/* You can add global styles to this file, and also import other style files */
/* .card-scrolled {
    position: fixed !important;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000; 
  transition: background-color 0.3s ease;
  } */
/* Apply RTL globally when Arabic is selected */
[dir="rtl"] {
  text-align: right;
  direction: rtl;
}
.form-switch .form-check-input {
  border-radius: 0 !important;
}
[dir="ltr"] {
  text-align: left;
  direction: ltr;
}
#developer td span:last-child span {
  display: none;
}
.star {
  color: red !important;
}

.card.deactive {
  display: none;
}

tr.suspend td {
  border: 1.5px solid red !important;
}

.action-text {
  color: initial;
  cursor: pointer;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.action-icons.dropdown-item:hover {
  color: #717ff5;
  text-decoration: none;
}

.action-icons.dropdown-item {
  color: #4154f1;
  text-decoration: none;
}

.ng-submitted .requiredField.ng-invalid {
  border-color: red;
}
.ng-submitted .requiredField.ng-invalid .ngx-dropdown-container .ngx-dropdown-button{
  border-color: red !important;
}
.multiselect-dropdown .selected-item {
  max-width: 100% !important;
}

.dateError {
  position: relative;
}

.custom-icon .fa.fa-money {
  color: green;
  font-size: 17px;
  cursor: pointer;
}

.validationerror {
  position: absolute;
  right: 10px;
  font-size: 13px;
  color: red;
}

div .text-success {
  color: #00b700 !important;
}

div .text-info {
  color: #a31acb !important;
}

.badge.bg-success {
  color: #00b700 !important;
  background: #e2ffe2 !important;
}

.badge.bg-warning {
  background-color: #ffedc4 !important;
  color: #e39d00 !important;
}

.badge {
  font-size: 12px !important;
  padding: 10px 15px !important;
}

.nav-item .badge {
  font-size: 10px !important;
  padding: 6px 10px !important;
}

.badge.bg-danger {
  color: #ff0000;
  background: #ffcabe !important;
}

.badge.bg-primary {
  color: #b8d935;
  background: #2d3e35 !important;
}

input[readonly] {
  background-color: #f7f7f7;
}

.btn.black {
  background: #414042;
  color: #fff;
}

.btn.red {
  background: #ff4500;
  color: #fff;
}

.ngx-dropdown-container .ngx-dropdown-button .display-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.pagination-next .ngx-pagination a,
.pagination-next .ngx-pagination button {
  color: #ffffff !important;
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
}

.ngx-pagination .pagination-previous span {
  display: none;
}

.ngx-pagination .pagination-next a {
  font-size: 0;
}

.ngx-pagination .pagination-next a::after {
  margin: 0;
  font-size: 16px;
}

.ngx-pagination .pagination-previous a {
  font-size: 0;
}

.ngx-pagination .pagination-previous a:before {
  margin: 0;
  font-size: 16px;
}

.ngx-pagination .pagination-next span {
  display: none;
}

.sidebar-nav .nav-content li {
  margin-bottom: 5px;
}

.dropdown-menu li hr {
  border: none;
}

input[type="date"]::after {
  content: "\f133";
  font-family: FontAwesome;
  position: absolute;
  right: 10px;
  padding: 5px;
  pointer-events: none;
  background: #fff;
}

input[type="date"] {
  position: relative;
}

.custom-row.permission .card-title {
  text-transform: capitalize;
}

.modal-header .close {
  border: 1px solid var(--second-color);
  background-color: var(--second-color);
  font-size: 24px;
  border-radius: 50%;
  padding: 0 10px;
  color: #fff;
  cursor: pointer;
}

.modal-header .close span {
  color: #fff;
}

.modal-header {
  border: 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 999;
  transform: translate(50%, -50%);
}

.modal-body {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.child-error-message {
  color: #fff;
  position: absolute;
  right: 2px;
  font-size: 12px;
  background: red;
  padding: 0px 7px;
  bottom: -9px;
}

form .error-message {
  position: relative;
}

.form-control {
  padding: 9px 15px !important;
}

.form-select {
  padding: 9px 15px !important;
}

.ngx-dropdown-container .ngx-dropdown-button {
  padding: 9px 15px !important;
}

.form-control:focus {
  border-color: var(--color-one) !important;
  box-shadow: 0 0 3px 0 var(--color-one) !important;
}

.form-select:focus {
  border-color: var(--color-one) !important;
  box-shadow: 0 0 3px 0 var(--color-one) !important;
}

.spinner-border.text-primary {
  color: var(--second-color) !important;
}

.permission .checkbox-field {
  padding: 5px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  position: relative;
}

input[type="checkbox"]::after {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
}

input[type="checkbox"]:disabled::after {
  background-color: #ddd;
}

input[type="checkbox"]:checked::after {
  display: none;
}

input[type="checkbox"]::before {
  content: "\f00c";
  font-family: FontAwesome;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #3f3a2b;
  color: #e8c444;
  line-height: 17px;
  font-size: 13px;
  text-align: center;
  display: none;
}

input[type="checkbox"]:checked::before {
  display: block;
}

.permission .card > .row {
  row-gap: 30px;
}

/* .table thead {
    vertical-align: bottom;
    background-color: #2c2e74;
} */
button.btn.btn-primary.custom-button-gentrate {
  position: absolute;
  top: 56%;
}
.card-body {
  position: relative;
}
#dashboard-tabs .bi {
  color: #fff;
  background-color: var(--color-one);
  line-height: 30px;
  width: 30px;
  text-align: center;
  display: inline-block;
  height: 30px;
  border-radius: 8px;
}

#dashboard-tabs .card .row {
  flex-direction: column-reverse;
  align-items: start !important;
}

#dashboard-tabs .card .row .col-auto {
  margin-bottom: 20px;
}

#dashboard-tabs .card .row .col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#dashboard-tabs .card .row .h5 {
  font-size: 14px;
}

#dashboard-tabs .card .row .text-xs {
  font-size: 15px;
  font-weight: 600;
}

#dashboard-tabs .card p {
  margin: 0;
  margin-top: 10px;
  font-size: 15px;
}

#dashboard-tabs .card p i {
  padding-right: 5px;
}

.btn-card {
  display: flex;
  align-items: end;
}

i.bi.bi-trash3 {
  color: red;
}

.action-icons i {
  font-size: 17px;
  cursor: pointer;
}

i.bi.bi-pencil-square {
  color: #e8c444;
  background: #3f3a2b;
  padding: 5px;
  border-radius: 5px;
}

.recordnotfound {
  font-size: 20px;
}

/* .table thead th {
    border: 1px solid #a71e1e;
    padding: 5px;
    font-size: 14px;
    color: #ffffff;

} */
.header-nav .profile .dropdown-item.d-flex.align-items-center {
  cursor: pointer;
}

select.form-select {
  color: #484848;
}

.table thead {
  vertical-align: bottom;
  background-color: #ffffff;
}

.table th {
  white-space: pre;
}

.table thead th span {
  padding: 0;
  display: block;
  width: max-content;
}

span.online {
  color: #00b700;
  background: #e2ffe2;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
}

span.offline {
  color: #ff0000;
  background: #ffcabe;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
}

.table tbody tr td {
  font-size: 14px;
}

.custom-width {
  width: 12%;
}

.ngx-pagination .current {
  background-color: var(--color-one) !important;
}

.pagination-previous,
.pagination-next {
  background-color: var(--color-one);
  color: #ffffff !important;
}

.pagination-previous:hover a,
.pagination-next:hover a {
  background-color: var(--color-one) !important;
  opacity: 0.7;
}

.pagination-previous.disabled,
.pagination-next.disabled {
  background-color: var(--color-one) !important;
}

.ngx-pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
}

.ngx-pagination li {
  height: 40px;
  width: 40px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
  border-radius: 12px !important;
}

.custom-icons i {
  font-size: 17px;
  cursor: pointer;
}

.custom-icons .bi.bi-lock-fill.active {
  color: green;
}

.fa-ban.active {
  color: red;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: bold;
}

.table thead th {
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 14px;
  color: #000000;
  padding: 8px;
  line-height: 1.42857143;
}

.table thead {
  vertical-align: bottom;
  background-color: #ffffff;
}

.custom-width.py-4.mt-1.pe-0 {
  width: 12%;
}

.custom-icons .fa-check {
  color: green !important;
  font-size: 17px;
}

.custom-icons .fa.fa-times-circle {
  color: red !important;
  font-size: 17px;
}

h5.card-title {
  font-size: 15px;
}

.card-body label.form-check-label {
  font-size: 14px;
}

.card-body {
  flex: inherit !important;
}

.ngx-dropdown-container .ngx-dropdown-list-container {
  top: 44px !important;
}

.ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down.up {
  top: -4px !important;
}

.ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  top: 4px !important;
}

.multiselect-dropdown .dropdown-btn {
  padding: 9px 12px !important;
}

.table-overflow {
  overflow-x: auto;
}

#dashboard-tabs .card {
  height: 100%;
}

/****** Tabs css *****/
.tabset .nav-tabs .nav-item a {
  border-color: #006a4e;
  background-color: #fff;
  color: inherit;
}

.tabset .nav-tabs .nav-item {
  margin: 0px 2px;
}

.tabset .nav-tabs {
  border: none;
}

.tabset .nav-tabs .nav-item a.active {
  background-color: #006a4e;
  color: #fff;
}

.tabset .nav-tabs .nav-item a.active:after {
  content: "\f00c";
  font-family: "FontAwesome";
  padding-left: 5px;
}

/* home css :start */
:root {
  --main-color: #006a4e;
  --second-color: #f42a41;
  --main-txt-color: #283c80;
}

body.dark-mode .home-page {
  background-color: #19222c;
  color: #fff;
  --main-txt-color: #ffffff;
  --main-color: #3c4259;
  --second-color: #27373e;
}

.dark-mode .h1,
.dark-mode .h2,
.dark-mode .h3,
.dark-mode .h4,
.dark-mode .h5,
.dark-mode .h6,
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
  color: #fff !important;
}

body.dark-mode .home-page .home-nav.active {
  background: #19222c;
}

body.dark-mode .home-nav {
  background: transparent;
}

body.dark-mode .home-page .nav-menu-item a {
  color: #ffffff !important;
}

body.dark-mode .home-page div#darkModeToggleBtn {
  border: 2px solid #ffffff;
  color: #ffffff;
  background: #19222c;
}

body.dark-mode .home-page .menu-logo .dark-mode {
  display: block !important;
}

body.dark-mode .home-page .menu-logo .logo-img {
  display: none;
}

.fa-moon::before {
  content: "\f186";
  font-family: FontAwesome;
  font-style: normal;
  cursor: pointer;
}

body.dark-mode .home-page .fa-moon::before {
  content: "\f185";
}

body.dark-mode .home-page .payment-option {
  box-shadow: 0 0px 15px 0 #ddd;
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  font-size: 20px;
  padding: 10px;
  opacity: 1;
  border-radius: 35px;
  color: #ffffff;
  background: linear-gradient(45deg, #283c80, #1b9dd7);
}

.slick-prev {
  left: -60px !important;
}

.slick-next {
  right: -40px !important;
}

.slick-initialized .slick-slide {
  padding: 30px 0;
}

.slider-two {
  max-width: 560px;
  padding: 0 60px;
}

.slider-two .slick-prev {
  left: 0px !important;
}

.slider-two .slick-next {
  right: 20px !important;
}

.slider-three {
  padding: 0 60px;
}

.slider-three .slick-prev {
  left: 0px !important;
}

.slider-three .slick-next {
  right: 20px !important;
}

@media (max-width: 767px) {
  .header {
    padding-right: 4px !important;
    height: 80px !important;
  }

  .header .search-bar {
    min-width: auto !important;
    padding: 0 !important;
  }

  /* home css */
  .slider-two {
    padding: 0 40px;
  }

  .slick-slider .slick-prev::before,
  .slick-slider .slick-next::before {
    font-size: 12px;
    padding: 7px;
  }

  .slider-two .slick-prev {
    left: 13px !important;
  }

  .slider-three {
    padding: 0 40px;
  }

  .slider-three .slick-prev {
    left: 13px !important;
  }

  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px !important;
  }

  /* home css */

  #header li.nav-item.d-block.d-lg-none {
    display: none !important;
  }

  #header .header-logo {
    width: 100%;
    background-color: transparent;
    padding-left: 20px;
  }

  .section table {
    white-space: pre;
  }

  .section .card-body {
    min-height: 150px !important;
    overflow-x: scroll;
  }

  .section .card-body h3 {
    font-size: 18px;
  }

  .card .card-body .row .col {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card .card-body .row .col-4.text-end.mb-2.mx-auto {
    width: 100%;
  }

  .card .card-body .col-8.mb-4.text-start {
    width: 100%;
  }

  .card .card-body .row .col .font-weight-bold {
    font-size: 14px;
    font-weight: 600;
  }

  .card .card-body .row .col-md-6.mt-4.text-end {
    margin-bottom: 20px;
  }

  .card {
    margin-bottom: 10px;
  }

  .card .card-body form .col-1 {
    width: 33.33%;
  }

  .card .card-body form .col-2 {
    width: 33.33%;
  }

  .card .card-body form .col-3 {
    width: 33.33%;
  }

  .card .card-body form .col-2.text-start {
    display: flex;
  }

  .card .card-body form .text-start.col-4 {
    display: flex;
  }

  .card .card-body form .text-start.col-3 {
    display: flex;
  }

  .card .card-body form .col-2.text-start.pt-0 {
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
  }

  .card .card-body form .form-control {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .card .card-body form .form-select {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .card .card-body form button {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .card .card-body .text-end {
    text-align: left !important;
    margin-top: 0 !important;
  }

  .card .card-body .row .text-end button {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 20px;
    margin: 0 !important;
    margin-right: 10px !important;
  }

  .card .card-body .row .col-md-2.mt-4.text-end {
    margin-bottom: 20px;
  }

  .card .card-body .custom-pagination {
    float: none;
    display: flex;
    justify-content: center;
  }

  .table tbody tr td {
    line-height: 18px;
  }

  .card .card-body div nav {
    display: flex;
    justify-content: center;
  }

  .card .card-body div nav ul {
    padding: 0;
  }
}
.powered_footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 11;
}
